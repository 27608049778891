/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";
import { keyframes } from "@emotion/core";
import TextAssistant24 from "../Base/text/TextAssistant24";
// import NextPageArrow from "../Navigation/NextPageArrow";
import { mediaQueries } from "../../configs/_configSite";
import TitleMain from "../Base/titles/TitleMain";

// Images
// import ImgBackgroundVOTIntro from "../Images/VOT/ImgBackgroundVOTIntro";
import Butterfly from "../../images/victor-over-triple/butterfly.png";
import FeaturesVOTSlider from "../Features/FeaturesVOTSlider";
import VideoPLayerFullScreen from "../VideoPlayers/VideoPlayerFullScreen";
import VideoFile from "../../videos/video-vot-intro-back.mp4";
import { LinkPurchaseExt } from "../Base/links/LinkPurchase";

const ContainerMain = styled(Div100vh)`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ContainerIntroText = styled.div`
  position: absolute;
  display: inline-table;
  box-sizing: border-box;
  z-index: 2;
  opacity: 0;
  width: 570px;
  right: calc(50vw - 600px);
  margin: auto;
  top: 32vh;
  bottom: 0;
  animation: ${fadeIn} 0.5s ease-in 2s forwards;
  transform-origin: bottom left;

  &:before {
    content: "";
    display: block;
    background-image: url(${Butterfly});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1000;
    width: 119px;
    height: 72px;
    position: absolute;
    right: -80px;
    top: -40px;
  }

  h1 {
    text-align: center;
    color: #000;
  }
  p {
    color: #000;
    line-height: 125%;
  }

  @media (max-width: ${mediaQueries.lg}px) {
    top: auto;
    left: 0;
    max-width: 690px;
    bottom: 400px;
    right: 0;
    margin: 0 auto;
    padding: 15px 30px;
    width: 100%;
  }

  @media (max-width: ${mediaQueries.md}px) {
    bottom: 15vh;
    max-width: none;
    width: 100%;
    padding: 0 15px;
    @media (max-width: ${mediaQueries.sm}px) {
      bottom: 80px;
    }
    @media (max-width: ${mediaQueries.xs}px) {
      p {
        font-size: 2vh;
      }
    }
  }
`;

// const DonateLinkStyled = styled.a`
//   font-family: ${fonts.primary};
//   text-decoration: none;
//   font-size: 2rem;
//   background-color: ${colors.gold};
//   color: #fff;
//   padding: 0.5rem 1rem;
//   margin-left: auto;
//   float: right;
//   display: block;
//   transition: all 0.2s;
//   &:hover {
//     background-color: #fff;
//     color: ${colors.gold};
//   }
//   @media (max-width: ${mediaQueries.xs}px) {
//     float: none;
//     margin: 0 auto;
//     width: max-content;
//     font-size: 12px;
//   }
// `;

export default () => {
  return (
    <ContainerMain>
      <FeaturesVOTSlider />
      <ContainerIntroText>
        <TitleMain>A Beautiful Vestige</TitleMain>
        <TextAssistant24>
          Sharing through cookies purchases to fight against Triple Negative
          Breast Cancer and Donations. It’s hard sometimes to feel and embrace
          the truth that we are valued and made to shine with eachother by using
          the gifts we have from a walk thats uniquely our own. We need to
          remember from where that vestige of glory comes, and come together
          whenever we see it fade. So be encouraged and let that light shine out
          in the world, wear your crown!
        </TextAssistant24>
        <LinkPurchaseExt
          style={{
            float: "right"
          }}
          href="http://giving.lluh.org/deidramarieramos"
          target="_blank"
        >
          Donate Now
        </LinkPurchaseExt>
      </ContainerIntroText>
      {/* <NextPageArrow text="Product" /> */}
      {/* <ImgBackgroundVOTIntro /> */}
      <VideoPLayerFullScreen video={VideoFile} />
    </ContainerMain>
  );
};
