import { Link } from "gatsby";
import styled from "@emotion/styled";
import _configBase from "../../../configs/_configBase";
import { colors } from "../../../configs/_configSite";

const styles = `
  ${_configBase};
  position: relative;
  font-family: Assistant;
  padding: 10px 20px;
  background: ${colors.gold};
  border-radius: 30px;
  color: ${colors.white};
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s;

  &:hover {
    color: ${colors.gold};
    background: ${colors.white};
  }
`;

export const LinkPurchaseInt = styled(Link)`
  ${styles}
`;

export const LinkPurchaseExt = styled.a`
  ${styles}
`;
