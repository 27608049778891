/* eslint-disable no-plusplus */
/* eslint-disable no-const-assign */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import css from "@emotion/css";

import Image1 from "../../images/victor-over-triple/slides/1-shutterstock_514799557.jpg";
import Image2 from "../../images/victor-over-triple/slides/2-IMG-5778.jpg";
import Image3 from "../../images/victor-over-triple/slides/3-IMG-5777.jpg";
import Image4 from "../../images/victor-over-triple/slides/4-IMG-4701.jpg";
import Image5 from "../../images/victor-over-triple/slides/5-IMG-4685.jpg";
import Image6 from "../../images/victor-over-triple/slides/6-IMG-4676.jpg";
import Image7 from "../../images/victor-over-triple/slides/7-IMG-2886.jpg";
import Image8 from "../../images/victor-over-triple/slides/8-IMG-2753.jpg";
import Image9 from "../../images/victor-over-triple/slides/9-IMG-1878.jpg";
import Image10 from "../../images/victor-over-triple/slides/10-IMG-1680.jpg";
import Image11 from "../../images/victor-over-triple/slides/11-IMG-0538.png";
import Image12 from "../../images/victor-over-triple/slides/12-IMG_6882.jpg";
import Image13 from "../../images/victor-over-triple/slides/13-Breast cancer pink ribbon.jpg";
import Image14 from "../../images/victor-over-triple/slides/14-32AFB44F-6C4F-47A5-B45F-4C4E17A96688.jpg";

import { mediaQueries } from "../../configs/_configSite";
import RibbonBack from "../../images/victor-over-triple/ribbon-back.png";
import RibbonFront from "../../images/victor-over-triple/ribbon-front.png";

const Container = styled.section`
  z-index: 1;
  position: absolute;
  height: 875px;
  width: 945px;
  top: 5vh;
  left: 0;
  transform-origin: top left;
  text-align: center;
  font-size: 90vh;
  height: 1em;
  width: 1.08em;

  &:before {
    content: "";
    display: block;
    top: 0;
    right: 4.5%;
    position: absolute;
    z-index: 0;
    width: 89%;
    height: 62%;
    background: url(${RibbonBack}) top left / contain no-repeat;
  }
  &:after {
    content: "";
    display: block;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 62%;
    position: absolute;
    background: url(${RibbonFront}) bottom right / contain no-repeat;
  }

  @media (max-width: ${mediaQueries.lg}px) {
    font-size: 75vh;
  }
  @media (max-width: ${mediaQueries.md}px) {
    font-size: 50vh;
    margin: 0 auto;
    top: 60px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    font-size: 20vh;
  }
  @media (max-width: ${mediaQueries.xxs}px) {
    font-size: 30vh;
  }
`;

const ContainerImage = styled.div(
  () => css`
    position: absolute;
    bottom: 23%;
    right: 13%;
    width: 48%;
    height: 68%;
    margin: 0 auto;
    z-index: 1;
    transition: opacity 0.5s ease;
    opacity: 0;
  `,
  ({ visible }) =>
    visible &&
    css`
      transition: opacity 0.5s 0.5s ease;
      opacity: 1;
    `
);

const images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14
];
export default () => {
  const [i, setI] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setI((i + 1) % images.length);
    }, 6000);
    return () => {
      clearTimeout(timeout);
    };
  }, [i]);
  return (
    <Container>
      {images.map((img, idx) => (
        <ContainerImage
          visible={i === idx}
          css={css`
            background: url(${img}) no-repeat center/contain;
          `}
        />
      ))}
    </Container>
  );
};
