import React from "react";

// Components
import { Global } from "@emotion/core";
import Layout from "../../components/Layouts/Layout";
import SEO from "../../components/Utilities/SEO";
import SliderVertical from "../../components/Sliders/SliderVertical";
import { ProviderEntryUI } from "../../contexts/context-entry-ui";
import FeaturesAudioPlayer from "../../components/Features/FeaturesAudioPlayer";
import AudioFile from "../../audio/walk-by-faith.mp3";
import ImgSlideTransitionVictorOverTriple from "../../components/Images/ImgSlideTransitionVictorOverTriple";
import SectionVOTIntro from "../../components/Sections/SectionVOTIntro";
import SectionVOT2 from "../../components/Sections/SectionVOT2";
import SectionVOT3 from "../../components/Sections/SectionVOT3";
import SectionPhoneCarrierToggles from "../../components/Sections/SectionPhoneCarrierToggles";

const slides = [
  <SectionVOTIntro />,
  <SectionVOT2 />,
  <SectionVOT3 />,
  <SectionPhoneCarrierToggles />
];

export default () => {
  return (
    <Layout scrollLock>
      <SEO
        title="Know Believe Fulfill | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <Global
        styles={{
          ".nav-item-beautiful-vestiges": {
            color: "white"
          }
        }}
      />
      <ProviderEntryUI>
        {/* <VideoPopup url={IntroVideo} image={VideoCover} /> */}
        <FeaturesAudioPlayer title="We Walk By Faith" audio={AudioFile} />
        <SliderVertical
          slides={slides}
          slideImage={<ImgSlideTransitionVictorOverTriple />}
        />
      </ProviderEntryUI>
    </Layout>
  );
};
