/* eslint-disable react/prop-types */
import React from "react";
// import styled from "@emotion/styled";
// import TextMain from "../Base/text/TextMain";
// import { mediaQueries } from "../../configs/_configSite";

// const Button = styled.button`
//   position: absolute;
//   box-sizing: border-box;
//   outline: none;
//   padding: 0;
//   margin: 0;
//   box-shadow: none;
//   border: none;
//   background: transparent;
//   text-align: center !important;
//   bottom: 30px;
//   margin: 0 auto;
//   left: 0;
//   right: 0;
//   z-index: 3;

//   transition: 0.2s;

//   p {
//     color: #ffffff !important;
//     margin-bottom: 15px;
//     transition: 0.2s;
//     text-align: center;
//   }

//   svg path {
//     transition: 0.2s;
//   }

//   &:hover {
//     p {
//       color: #d7b401 !important;
//     }
//     svg path {
//       fill: #d7b401 !important;
//     }
//   }
//   @media (max-width: ${mediaQueries.sm}px) {
//     bottom: 15px;
//     p {
//       margin-bottom: 5px;
//     }
//     svg {
//       width: 25px;
//       height: 15px;
//     }
//   }
// `;

// export default ({ text }) => {
export default () => {
  return (
    <></>
    // <Button>
    //   <TextMain>{text}</TextMain>
    //   <svg
    //     width="50"
    //     height="29"
    //     viewBox="0 0 50 29"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path
    //       d="M27.8284 27.1716C26.2663 28.7337 23.7337 28.7337 22.1716 27.1716L1.82843 6.82844C-0.691427 4.30858 1.09323 5.50651e-06 4.65685 5.14249e-06L45.3431 9.86436e-07C48.9068 6.22417e-07 50.6914 4.30857 48.1716 6.82843L27.8284 27.1716Z"
    //       fill="white"
    //     />
    //   </svg>
    // </Button>
  );
};
